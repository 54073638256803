import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Oxford({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div className="formkit-container">
        <Elements.Heading
          className="formkit-preheader"
          name="preheader"
          defaults={{ content: "Enter a catchy page headline" }}
        />
        <div className="formkit-card-container">
          <div className="formkit-card" style={styles.forElement("card")}>
            <div
              className="formkit-card-background"
              style={{
                backgroundColor: styles.forElement("card_bg").backgroundColor
              }}
            />
            <div
              className="formkit-card-background"
              style={styles.forElement("card_bg")}
            />
            <Elements.Heading
              className="formkit-header"
              name="header"
              defaults={{ content: "Enter a catchy page headline" }}
            />
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content: "Provide some more detail in this subtitle"
                }}
              />
            </Elements.Region>
            <Elements.Form
              style={styles.forElement("form", {
                backgroundColor: "#ffffff"
              })}
            >
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
              <Elements.Content
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </Elements.Form>
            <Elements.BuiltWith background="card_bg" image="card_bg" />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Oxford.style = "landing_page"
Oxford.categories = ["Waitlist"]
Oxford.thumbnail = ""
Oxford.preview = "https://demo.ck.page/oxford"
Oxford.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Oxford, { name: "Oxford" })
